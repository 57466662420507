import axios from "axios";

const Fetch = async (url: string, token?: string, raw: boolean = false): Promise<Response | any> => {
  const tokenHeader = token ? { "Authorization": `Bearer ${token}` } : {};
  const res = await fetch(url, {
    method: "GET",
    headers: new Headers({
      'Content-Type': "application/json",
      ...tokenHeader,
    }),
    credentials: "include"
  });

  if (raw) {
    return res;
  }

  return (await res.json());
}

const axiosInstance = () => {
  const headers = { 'Accept': 'application/json' }

  return axios.create({
    baseURL: process.env.NEXT_PUBLIC_NEST_API_URL,
    timeout: 5000,
    withCredentials: true,
    headers,
  });
}

export default Fetch;
export { axiosInstance };
